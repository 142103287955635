<template>
    <div>
        <b-row>
            <b-col>
                <!-- card-congratulations -->
                <b-card class="earnings-card   card-congratulations bg-warning">
                <b-row>
                    <b-col cols="5">
                        <b-card-title class="mb-1">
                            <h4></h4>
                            <h2 class="text-white"><b>Total</b></h2>
                            <h4  class="text-white">Prosentase</h4>
                            <h4  class="text-white">Pengaduan</h4>
                            <h4  class="text-white">Ditanggapi</h4> 
                        </b-card-title>
                        <!-- <div class="font-small-2">
                            Aduan 
                        </div>
                        <h3 class="mb-1 text-white">
                             100.000 
                        </h3>
                        <div class="font-small-2">
                            Aduan Tertanggapi 
                        </div>
                        <h3 class="mb-1 text-white">
                             100.000   
                        </h3> -->
                        
                        <!-- <b-card-text class="text-muted font-small-2">
                    <span class="font-weight-bolder">68.2%</span><span> more earnings than last month.</span>
                </b-card-text> -->
                    </b-col>
                    <b-col cols="7"  >
                        <!-- chart -->
                        <vue-apex-charts  height="160" :options="earningsChart.chartOptions" :series="series" />
                    </b-col>
                </b-row>
                <br>
            </b-card></b-col>
        </b-row>
      

       
    </div>
</template>

<script>
    import {
        BCard,
        BRow,
        BCol,
        BCardTitle,
        BCardText,
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import {
        $themeColors
    } from '@themeConfig'

    const $earningsStrokeColor2 = '#28c76f66'
    const $earningsStrokeColor3 = '#28c76f33'
    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BCardTitle,
            BCardText,
            VueApexCharts,
        },
        props: {
            data: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                prosesn : '70',
                series: [50, 50],
                earningsChart: {
                    chartOptions: {
                        chart: {
                            type: 'donut',
                            toolbar: {
                                show: false,
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        legend: {
                            show: false
                        },
                        comparedResult: [2,  8],

                        stroke: {
                            width: 0
                        },
                        colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
                        grid: {
                            padding: {
                                right: -20,
                                bottom: -8,
                                left: -20,
                            },
                        },
                        plotOptions: {
                            pie: {
                                startAngle: -10,
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            offsetY: 15,
                                        },
                                        value: {
                                            offsetY: -15,
                                            formatter(val) { 
                                                return `${parseInt('70')}%`
                                            },
                                        },
                                        total: {
                                            show: true,
                                            offsetY: 15,
                                            label: 'App',
                                            formatter() {
                                                return '80%'
                                            },
                                        },
                                    },
                                },
                            },
                        },

                    },
                },
            }
        },
    }
</script>