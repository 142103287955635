<template>
  <div> 
    <admin v-if="status_akses.level_name =='super roots'"></admin>
    <user v-if="status_akses.level_name == 'Pengaduan Masyarakat'" :profil = status_akses></user>
    <pelajar v-if="status_akses.level_name =='Pelajar Pelopor Keselamatan'"></pelajar>
  </div>
</template>

<script>
  import Base from '@/config/Mixins_base';
  import axios from '@/config/Axios';
  import admin from './admin/index.vue';
  import user from './user/index.vue'
  import pelajar from './pelajar/index.vue'
  import {
    BRow,
    BCol
  } from 'bootstrap-vue'

  export default {
    components: {
      BRow,
      BCol,
      admin,
      user,pelajar
    },

    mixins: [Base],
    data() {
      return {
        admin: 1,
        status_akses : {}
      }
    },
    mounted() {
      this.cek_akses();
      this.cek_token();
    },
    methods: {
      async cek_akses() {
          const self = this;
          await axios({
            method: 'PUT',
            url: '/api/dashboard/cek',
            data: {
              
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
            })
            .then(function (response) {
            self.status_akses = response.data.result;       
            }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
            });
          },
      loadDasboard(st) {
        this.admin = st
      }
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>
