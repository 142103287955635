<template>
    <div>
        <!-- match-height -->
        <b-row class="mb-5 mt-5">
            <b-col md="3" class="mb-5 mt-5"></b-col>
            <b-col md="6">
                <b-img fluid :src="require('@/assets/images/pages/logo-1.png')" alt="Login V2" />
            </b-col>
        </b-row>
        <b-row class="mb-5">
            <b-col md="4" class="mb-5">
                .
                <!-- <b-img fluid :src="require('@/assets/images/pages/coming-soon.svg')" alt="Login V2" />
                <ProsentaseSerapanAnggaran></ProsentaseSerapanAnggaran> -->
            </b-col>
            <b-col md="8">
                <!-- <statistik :statisticsItems="statistikAnggaran"></statistik>
                <statistik style="margin-top: -10px;;" :statisticsItems="statistijJenisKegiatan"></statistik> -->
            </b-col>

        </b-row>
        <b-row class=" ">
            <!-- <b-col md="4">
                <Kegiatan></Kegiatan>
            </b-col> -->
            <b-col md="12">
                <!-- <b-card>
                    <tabel-serapan></tabel-serapan>
                </b-card> -->
            </b-col>

        </b-row>


    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BImg,
    bCard,
    bMediaAside,
    BMedia,
    BAlert,
    BMediaBody,
    BCardText,
    BCardTitle,
} from 'bootstrap-vue'
import profilOpd from './component/profilOpd.vue'
import Statistik from './component/statistik.vue'
import KategoriKegiatan from './component/kategoriKegiatan.vue'
import ProsentaseSerapanAnggaran from './component/prosentaseSerapanAnggaran.vue'
import Kegiatan from './component/Kegiatan.vue'
import TabelSerapan from './component/TabelSerapan.vue'

export default {
    components: {
        profilOpd,
        BRow,
        BCol,
        Statistik,
        BCard,
        BAvatar,
        BImg,
        bMediaAside,
        BMedia,
        BAlert,
        BMediaBody,
        BCardText,
        KategoriKegiatan,
        ProsentaseSerapanAnggaran,
        BCardTitle,
        Kegiatan,
        TabelSerapan,
    },
    data() {
        return {
            sideImg: require('@/assets/images/pages/coming-soon.svg'),

            statistikAnggaran: [{
                icon: '',
                color: 'text-primary',
                title: '1.200  <small>aduan</small>  ',
                subtitle: 'Total Pengaduan ',
                customClass: 'mb-2 mb-xl-0',
            },
            {
                icon: '',
                color: 'text-primary',
                title: '900 <small>aduan</small>',
                subtitle: ' <small>Pengaduan Ditanggapi</small>',
                customClass: 'mb-2 mb-xl-0',
            },
            {
                icon: '',
                color: 'text-primary',
                title: '500 <small>aduan</small>',
                subtitle: 'Belum Ditanggapi',
                customClass: 'mb-2 mb-sm-0',
            },
            ],

            statistijJenisKegiatan: [{
                icon: 'BoxIcon',
                color: 'light-danger',
                title: '500 <br><sub><small>titik</small></sub>',
                subtitle: 'Macet',
                customClass: 'mb-2 mb-sm-0',
            }, {
                icon: 'TrendingUpIcon',
                color: 'light-primary',
                title: '50 <br><sub><small>titik</small></sub>',
                subtitle: 'Laka',
                customClass: 'mb-2 mb-xl-0',
            },
            {
                icon: 'UserIcon',
                color: 'light-info',
                title: ' 30 <br><sub><small>titik</small></sub> ',
                subtitle: 'Yang Lainnya',
                customClass: 'mb-2 mb-xl-0',
            },

            ],
        }
    }

}
</script>

<style></style>