import { render, staticRenderFns } from "./aduan.vue?vue&type=template&id=5982b0bc&"
import script from "./aduan.vue?vue&type=script&lang=js&"
export * from "./aduan.vue?vue&type=script&lang=js&"
import style0 from "./aduan.vue?vue&type=style&index=0&id=5982b0bc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports