<template>
    <div>
        <b-row>
            <b-col md="12">
                <h5 class="text-primary">Pendaftaran Pelopor Keselamatan Tahun 2022</h5>
                <h6>Ketentuan</h6>
                <p style="text-align :justify"><small>Tootsie roll lollipop lollipop icing. Wafer cookie
                        danish macaroon. Liquorice
                        fruitcake apple pie I love cupcake cupcake. I love cupcake cupcake. I love cupcake
                        cupcake. I love cupcake cupcake. I love cupcake cupcake. I love cupcake
                        cupcake.</small></p>
                <b-row>
                    <b-col md="4">
                        <small>Kategori</small>
                        <h6 class="text-warning">PELAJAR</h6>
                    </b-col>
                    <b-col md="4">
                        <h6><small>Tanggal pendaftaran </small>
                            <br> <span class="text-primary">01 Januari 2024 </span> </h6>
                    </b-col>
                    <b-col md="4">
                        <h6><small>Sampai Dengan</small><br> <span class="text-warning">30 januari 2024 </span></h6>
                    </b-col>
                </b-row> 
                <b-button block variant="primary" size="sm"  v-b-modal.modal-1>
                    <feather-icon icon="AirplayIcon" size="15"></feather-icon> Ajkan Pendaftaran
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BAvatar,
        BButton,
        BImg,
    } from 'bootstrap-vue'
    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BAvatar,
            BAvatar,
            BButton,
            BImg

        }
    }
</script>

<style>

</style>