<template>
    <div>
      <b-row  class="match-height">
        <b-col md="5">
           <b-card>
           
          <b-img :src="require('@/assets/images/pages/pelopor_keselamatan.png')" class="congratulations-img-left" fluid /> 
          <h4 class="text-primary text-center mt-1">Selamat Datang Pelajar Peopor Keselamatan</h4>
           </b-card>
        </b-col>
        <b-col md="7">
          <b-card>
            <h2 class="text-warning"><feather-icon icon ="InfoIcon" size ="25"></feather-icon> Informasi</h2>
            <detail_pendaftaran></detail_pendaftaran>
          </b-card> 
        </b-col>
      </b-row>  
    </div>
  </template>
  
  <script>
    import {
      BRow,
      BCol,
      BCard,
      BAvatar,
      BButton,
      BImg,
    } from 'bootstrap-vue'  
    import detail_pendaftaran from './component/detail_pendaftaran.vue'; 
    export default {
      components: {
        BRow,
        BCol,
        BCard,
        BAvatar,
        BAvatar,
        BButton,
        BImg, 
        detail_pendaftaran, 
      }
    }
  </script>
  
  <style>
  
  </style>