<template>
  <div class=" ">
    <b-row class="match-height">

      <b-col md="4">
        <aduan :profil=profil :jm_data = jm_data></aduan>
      </b-col>
      <b-col md="8">
        <b-card>
          <b-img fluid :src="imgs" alt="Login V2" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Base from '@/config/Mixins_base' 

  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import aduan from './component/aduan.vue'
  import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BCard
  } from 'bootstrap-vue'

  import {
    togglePasswordVisibility
  } from '@core/mixins/ui/forms'

  import axios from '@/config/Axios'
  import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
  export default {
    components: {
      BRow,
      BCol,
      BLink,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BCardText,
      BCardTitle,
      BImg,
      BForm,
      BButton,
      VuexyLogo,
      FeatherIcon,
      BCard,
      aduan
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        jm_data : {},
        imgs: require('@/assets/images/pages/layanan_masyarakat.png'),
      }
    },
    mixins: [Base],

    props: {
      profil: {}
    },
    computed: {
      imgUrl() {},
    },
    mounted() {
      this.jm_aduan();
    },
    methods: {
      async jm_aduan() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/pengaduan/masyarakat/jm_aduan',
            data: {
              status: self.status
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.jm_data = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>