<template>
    <div class=" ">
        <b-card class="card-congratulations-primary  bg-primary match-height text-white" v-b-modal.modal-1
            @click="act_data('')"> 
            <div class="media">
                <div class="media-body my-auto text-center text-white">
                    <h5 class="font-weight-bolder mb-0 text-white"> <small>Selamat Datang </small></h5>
                    <h4 class="text-white">{{ profil.nm_lengkap }}</h4>
                    <h6 class="text-white"><small>Username {{ profil.username }}</small></h6>
                </div>
            </div>
        </b-card>
        <b-card class="card-congratulations bg-warning match-height text-white" v-b-modal.modal-1 @click="act_data('')">
            <router-link to="/pengaduan_masyarakat">
                <div class="row">
                    <div class="col-sm-12 col-xl-12 mb-2 mb-xl-0">
                        <div class="media">
                            <div class="media-body my-auto ml-1">
                                <h6 class="font-weight-bolder mb-0 text-white"> Total Pengaduan </h6>
                                <h1 class="font-weight-bolder mb-0 text-white">{{ jm_data.jm }} </h1>
                              
                            </div>
                            <span class="b-avatar-custom text-white">
                                <feather-icon size='50' icon='TwitchIcon'></feather-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </router-link>
        </b-card>
        <router-link to="/pengaduan_masyarakat">
            <b-row> 
                <b-col md="6">
                    <b-card class="bg-danger match-height text-white" v-b-modal.modal-1 @click="act_data('')">
                        <div class="row">
                            <div class="col-sm-12 col-xl-12 mb-2 mb-xl-0">
                                <div class="media">
                                    <div class="media-body my-auto ml-1">
                                        <h1 class="font-weight-bolder mb-0 text-white" v-if="jm_data.jm_belum_ditanggapi == null ">0 <small>Aduan</small></h1>
                                        <h1 class="font-weight-bolder mb-0 text-white" v-if="jm_data.jm_belum_ditanggapi != null ">{{ jm_data.jm_belum_ditanggapi }} <small>Aduan</small></h1>
                                        <h6 class="font-weight-bolder mb-0 text-white"> <small>Belum Ditanggapi </small>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>
                <b-col md="6">
                    <b-card class="bg-info match-height text-white" v-b-modal.modal-1 @click="act_data('')">
                        <div class="row">
                            <div class="col-sm-12 col-xl-12 mb-2 mb-xl-0">
                                <div class="media">
                                    <div class="media-body my-auto ml-1">
                                        <h1 class="font-weight-bolder mb-0 text-white">{{ jm_data.jm_ditanggapi }} <small>Aduan</small></h1>
                                        <h6 class="font-weight-bolder mb-0 text-white"> <small>Sudah Ditanggapi </small>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </router-link> 
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard
    } from 'bootstrap-vue'

    import {
        togglePasswordVisibility
    } from '@core/mixins/ui/forms'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                imgs: require('@/assets/images/pages/layanan_masyarakat.png'),
            }
        },
        props: {
            profil: {},
            jm_data :{}
        },
        computed: {
            imgUrl() {},
        },
        methods: {

        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>